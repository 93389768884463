import { IAppReducer } from 'models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Images } from '../../../assets/images/images.index';

export const PaymentGatewayLink = ({
  providerProcessor,
  customerStripeId,
}: {
  providerProcessor: string;
  customerStripeId: string;
}) => {
  const user = useSelector((state: IAppReducer) => state.authReducer);

  const paymentData = useMemo(() => {
    if (!user?.agentId) return null;

    const isStripe = providerProcessor?.toLowerCase() === 'stripe';
    return isStripe
      ? {
          url: `https://dashboard.stripe.com/customers/${customerStripeId}`,
          img: Images.stripe,
          alt: 'Stripe Dashboard',
        }
      : {
          url: 'https://dashboard.dlocal.com',
          img: Images.dlocal,
          alt: 'DLocal Dashboard',
        };
  }, [providerProcessor, customerStripeId, user?.agentId]);

  if (!paymentData) return null;

  return (
    <a href={paymentData.url} target="_blank" rel="noopener noreferrer">
      <img src={paymentData.img} alt={paymentData.alt} />
    </a>
  );
};
