import { Card, Chips, ItemStatus } from 'components';
import { useSubscriptions } from 'pages/Settings/pages/Subscriptions/hooks/Subscriptions.hook';
import { Subscription } from 'shared/services/user-subscription';
import './Item-subscription.scss';
import { useProductTitle } from './hooks/Item-Subscription.hook';

interface ItemSubscriptionProps {
  id: string;
  products: string[];
  price: number;
  dateExpire: string;
  typePeriodicity: string;
  status: 'successful' | 'error';
}

const messageStatus = {
  successful: 'Activa',
  error: 'Inactiva',
};

export const ItemSubscription = ({
  id,
  products,
  price,
  dateExpire,
  typePeriodicity,
  status,
}: ItemSubscriptionProps) => {
  const { navigate } = useSubscriptions();
  const handleSubscriptionClick = (subId: Subscription['id']) => {
    navigate(`/settings/subscriptions/${subId}`);
  };

  const productTitle = useProductTitle(products);
  return (
    <div
      onClick={() => handleSubscriptionClick(id)}
      className="item__sub__container"
    >
      <Card>
        <div className="item__sub__content">
          <div className="item__sub__title">
            <span className="title--bold row">
              <span>{productTitle}</span>
            </span>
          </div>
          <div className="item__sub__detail">{`$${price} - ${dateExpire}`}</div>
          <div className="item__sub__status">
            <Chips label={typePeriodicity} />
            <ItemStatus
              status={status}
              message={messageStatus[status]}
            ></ItemStatus>
          </div>
        </div>
      </Card>
    </div>
  );
};
