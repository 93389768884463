import { IDetailStep } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getUserSubscriptionWithProducts,
  Subscription,
  SubscriptionWithProducts,
} from 'shared/services/user-subscription';

interface UseSubscriptionsReturn {
  steps: IDetailStep[];
  navigate: ReturnType<typeof useNavigate>;
  sub: SubscriptionWithProducts;
  loading: boolean;
  error: boolean;
}

const STEPS: IDetailStep[] = [
  {
    description: 'ajustes',
    state: 'active',
    link: '/settings',
    permissionToNavigate: true,
  },
  {
    description: 'suscripciones',
    state: 'active',
    link: '/settings/subscriptions',
    permissionToNavigate: true,
  },
  {
    description: 'detalle',
    state: 'active',
    link: '',
    permissionToNavigate: false,
  },
];

export const useSubscriptionDetails = (
  subId: Subscription['id'] | undefined
): UseSubscriptionsReturn => {
  const navigate = useNavigate();
  const [subscriptionWithProducts, setSubscriptionWithProducts] =
    useState<SubscriptionWithProducts>({} as SubscriptionWithProducts);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const getProductsBySubId = async () => {
      setLoading(true);
      setError(false);
      try {
        if (!subId) throw new Error('Subscription ID is missing');
        const sub = await getUserSubscriptionWithProducts(subId);
        setSubscriptionWithProducts(sub);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getProductsBySubId();
  }, [subId]);

  return {
    steps: STEPS,
    navigate,
    sub: subscriptionWithProducts,
    loading,
    error,
  };
};
