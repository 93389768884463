import cms from './cms';

const statusMap: { [key: string]: SubscriptionStatus } = {
  activa: 'successful',
};

type SubscriptionStatus = 'successful' | 'error';
type SubscriptionPeriodicity = 'ANUAL' | 'MENSUAL';

interface IProduct {
  product_id: {
    alias_title: string;
  };
}

interface ISubscriptionResponse {
  id: number;
  date_expire: string;
  stripe_subscription_id: string;
  amount: number;
  status_subscription_id: {
    name: string;
  };
  periocity: {
    name: string;
  };
  productos_usuarios: IProduct[];
}

export interface Subscription {
  id: string;
  products: string[];
  price: number;
  dateExpire: string;
  typePeriodicity: SubscriptionPeriodicity;
  status: SubscriptionStatus;
}

interface ProductDetailsViewResponse {
  id: number;
  amount: number;
  quantity: number;
  status: {
    name: 'successful' | 'error';
  };
  product_id: {
    id: number;
    alias_title: string;
  };
}

interface ISubscriptionWithProductsResponse {
  id: number;
  stripe_subscription_id: string;
  provider_processor: string;
  date_init: string;
  date_expire: string;
  amount: number;
  periocity: {
    name: string;
  };
  user_id: {
    customer_stripe_id: string;
  };
  productos_usuarios: ProductDetailsViewResponse[];
}

export interface ProductsDetailsView {
  productId: number;
  productName: string;
  quantity: number;
  amount: number;
  status: 'successful' | 'error';
}

export interface SubscriptionWithProducts {
  id: string;
  amount: number;
  products: ProductsDetailsView[];
  dateInit: string;
  dateExpire: string;
  typePeriodicity: SubscriptionPeriodicity;
  customerStripeId: string;
  providerProcessor: string;
}

const formatDate = (date_init: string): string => {
  const date = new Date(date_init);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const extractTitleProducts = (productos_usuarios: IProduct[]): string[] => {
  return productos_usuarios.map((product) => product.product_id.alias_title);
};

const formatPeriodicity = (
  periodicityName: string
): SubscriptionPeriodicity => {
  const upperName = periodicityName.toUpperCase();
  if (upperName !== 'ANUAL' && upperName !== 'MENSUAL') {
    throw new Error(`Periodicidad inválida: ${periodicityName}`);
  }
  return upperName as SubscriptionPeriodicity;
};

const formatStatus = (statusName: string): SubscriptionStatus => {
  return statusMap[statusName.toLowerCase()] || 'error';
};

const formatSubscriptionResponse = (
  response: ISubscriptionResponse[]
): Subscription[] => {
  if (!Array.isArray(response)) {
    throw new Error('La respuesta debe ser un array');
  }

  return response.map((subscription) => {
    if (!subscription || typeof subscription !== 'object') {
      throw new Error('Elemento de suscripción inválido');
    }
    const {
      id,
      amount,
      date_expire,
      status_subscription_id,
      periocity,
      productos_usuarios,
      stripe_subscription_id,
    } = subscription;

    if (
      !id ||
      !date_expire ||
      !stripe_subscription_id ||
      !periocity?.name ||
      !status_subscription_id?.name
    ) {
      throw new Error('Datos de suscripción incompletos');
    }

    return {
      id: stripe_subscription_id,
      dateExpire: formatDate(date_expire),
      products: extractTitleProducts(productos_usuarios),
      typePeriodicity: formatPeriodicity(periocity.name),
      status: formatStatus(status_subscription_id.name),
      price: amount,
    };
  });
};

const formatProducts = (
  productos_usuarios: ProductDetailsViewResponse[]
): ProductsDetailsView[] => {
  return (
    productos_usuarios.map((product) => ({
      productId: product.id,
      productName: product.product_id?.alias_title,
      quantity: product.quantity,
      amount: product.amount,
      status: formatStatus(product.status.name),
    })) || []
  );
};

const formatSubscriptionWithProductsResponse = (
  sub: ISubscriptionWithProductsResponse
): SubscriptionWithProducts => {
  const {
    id,
    amount,
    date_init,
    date_expire,
    stripe_subscription_id,
    provider_processor,
    periocity,
    user_id,
    productos_usuarios,
  } = sub;

  if (
    !id ||
    !date_init ||
    !date_expire ||
    !stripe_subscription_id ||
    !provider_processor ||
    !periocity?.name ||
    !user_id?.customer_stripe_id ||
    !Array.isArray(productos_usuarios)
  ) {
    throw new Error('Datos de los detalles de la suscripción incompletos');
  }

  return {
    id: stripe_subscription_id,
    dateInit: formatDate(date_init),
    dateExpire: formatDate(date_expire),
    amount: amount,
    typePeriodicity: formatPeriodicity(periocity.name),
    products: formatProducts(productos_usuarios),
    customerStripeId: user_id.customer_stripe_id,
    providerProcessor: provider_processor,
  };
};

export async function getUserSubscriptions(
  userId: number
): Promise<Subscription[]> {
  try {
    const response = await cms.get(
      `/user-subscriptions/user-products/${userId}`
    );
    return formatSubscriptionResponse(response.data);
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get subscriptions 🚨');
  }
}

export async function getUserSubscriptionWithProducts(
  subId: Subscription['id']
): Promise<SubscriptionWithProducts> {
  try {
    const response = await cms.get(
      `/user-subscription-with-products-by-subid/${subId}`
    );
    return formatSubscriptionWithProductsResponse(response.data);
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get subscription 🚨');
  }
}
