import './Subscription-details-header.scss';

interface SubscriptionDetailsHeaderProps {
  title: string;
  subtitle: string;
}

export const SubscriptionDetailsHeader = ({
  title,
  subtitle,
}: SubscriptionDetailsHeaderProps) => {
  return (
    <article
      className="sub-details-header"
      aria-label="Detalles de la suscripción"
    >
      <h1 className="sub-details-header__title">{title}</h1>
      <p
        className="sub-details-header__subtitle"
        aria-label={`ID: ${subtitle}`}
      >
        sub_id: {subtitle}
      </p>
    </article>
  );
};
