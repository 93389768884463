import { ButtonIndexProperty } from 'components/atoms/Button-index-property/Button-index-property';
import { Button } from 'components/atoms/Button/Button';
import { Input, TypeInput } from 'components/atoms/Input/Input';
import { ItemAddProperty } from 'components/atoms/Item-add-property/Item-add-property';
import { IOptionSelect, Select } from 'components/atoms/Select/Select';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { AddPropertySkeleton } from 'components/skeleton/organisms/Add-property-skeleton/Add-property-skeleton';
import { typeProperty as appConstants } from 'constants/app.constants';
import { useFormik } from 'formik';
import { TypeProperty } from 'models/app.interface';
import { IDetailStep } from 'models/info.steps.interface';
import { IAppReducer } from 'models/reducer.interface';
import {
  IAddPropertiy,
  IAtribute,
} from 'pages/Create-account/pages/Create-properties/models/Create-properties.interface';
import {
  IUserSubscription,
  IUserSubscriptionProductsData,
} from 'pages/Edit-Property/models/Edit-Property.interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getYear } from 'shared/constants/year.constants';
import { getUserPlans } from 'shared/services/plans';
import { createProductProperties } from 'shared/services/products';
import { getPropertyTypeById } from 'shared/services/properties.service';
import { getPropertyInfo } from 'utils/properties.utils';
import { ContactCard } from '../../components/molecules/Contact-card/Contact-card';
import './Add-property.scss';
import {
  CustomizedStateAddProperty,
  IAttributesPropertyData,
  ITypeProperty,
  ITypePropertyResponse,
} from './models/Add-property.inteface';

export const AddProperty = () => {
  const location = useLocation();
  const steps: IDetailStep[] = [
    {
      description: '',
      state: 'active',
      link: '',
      permissionToNavigate: false,
    },
  ];
  const [step, setStep] = useState<IDetailStep[]>(steps);

  const { typeProperty } = (location.state as CustomizedStateAddProperty) || 1;
  const navigate = useNavigate();
  const {
    authReducer: user,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);
  const userImpersonationId = user?.agentAuthData?.userId ?? null;
  const year = getYear(branch);
  const [popUp, setPopUp] = useState<{
    type: string;
    showModal: boolean;
    title: string;
    message: string;
    labelButton: string;
    labelButtonCancel: string;
    planId: string | number | undefined;
  }>({
    type: '',
    showModal: false,
    title: '',
    message: '',
    labelButton: '',
    labelButtonCancel: '',
    planId: undefined,
  });
  const [maximumNumberPropertiesAdd, setMaximumNumberPropertiesAdd] =
    useState(10);
  const [count, setCount] = useState<undefined | number>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stepProperties, setStepProperties] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [planesOptions, setPlanesOptions] = useState<IOptionSelect[]>([
    { value: '', description: '', info: '' },
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [atributes, setAtributes] = useState<ITypeProperty | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formAddPropertyInitialValues: any = {
    userProduct: undefined,
  };
  useEffect(() => {
    (async () => {
      const planOptions = (await getPlans(typeProperty || 1)) as any;
      const defaultOptions = planOptions.find((p: any) => {
        return !p.isDisabled && +(p.info as string).split(' ')[0] > 0;
      });
      handleSelectOption(
        defaultOptions ? (defaultOptions.value as number) : 0,
        planOptions
      );

      await getPropertyType(typeProperty || 1);
      setIsLoading(false);
    })();
  }, [setPlanesOptions]);
  const getPlans = async (idTypeProperty: number) => {
    try {
      const response: IUserSubscription[] = await getUserPlans(user.uid);
      const options = response.map((item: IUserSubscription) => {
        const userProductQuantity =
          item.attributes.plan_id.data.attributes.user_products[0]?.quantity ||
          0;
        const propertiesUser =
          item.attributes.plan_id.data.attributes.plan_properties?.length || 0;

        const calculateAvailableProperties = Math.abs(
          userProductQuantity - propertiesUser
        );

        return {
          value: item.attributes.plan_id.data.attributes.user_products[0].id,
          description: `${item.attributes.plan_id.data.attributes.alias_title} | ${item.attributes.plan_id.data.attributes.alias_product_type}`,
          info: `${calculateAvailableProperties} propiedades disponibles`,
          readonly: calculateAvailableProperties <= 0,
          isDisabled: validateTypeProduct(
            idTypeProperty,
            item.attributes.plan_id.data.attributes.productos.data
          ),
          id: item.id,
        };
      }, []);
      options.push({
        value: 0,
        description: 'Sin Cobertura',
        info: '',
        isDisabled: false,
        readonly: false,
        id: 0,
      });
      setStep([
        {
          description: getPropertyInfo(typeProperty).title,
          state: 'active',
          link: '',
          permissionToNavigate: false,
        },
      ]);
      setPlanesOptions(options);
      return options;
    } catch (error) {
      console.log('error', error);
    }
  };
  const getPropertyType = async (id: number) => {
    try {
      const response: ITypePropertyResponse = await getPropertyTypeById(id);
      setAtributes({ ...response, itemActive: 1 });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.propiedades.data.forEach((element) => {
        formAddProperty.values[
          `${response.name}__${element.attributes.name}__${element.id}__${1}`
        ] = '';
      });
    } catch (error) {
      console.log('err', error);
    }
  };
  const validateTypeProduct = (
    idTypeProperty: number,
    array: IUserSubscriptionProductsData[]
  ) => {
    let isDisabled = false;
    array.forEach((item) => {
      if (item.attributes.product_type_id.data.id !== idTypeProperty) {
        isDisabled = true;
      }
    });
    return isDisabled;
  };

  const handlePopUp = (
    type: 'exitsProcess' | 'selectPlan' | undefined,
    id?: string | number
  ) => {
    const infoSetPopUp = {
      showModal: true,
      title: '',
      message: '',
      labelButton: '',
      labelButtonCancel: '',
      type: '',
      planId: id,
    };
    if (type === 'exitsProcess') {
      infoSetPopUp.type = 'exitsProcess';
      infoSetPopUp.title = '¿Deseas salir? ';
      infoSetPopUp.message =
        'Esta por salir del proceso de agregar una nueva propiedad, tenga en cuenta que al dejarlo incompleto deberá iniciar nuevamente. ';
      infoSetPopUp.labelButton = 'Continuar';
      infoSetPopUp.labelButtonCancel = 'Salir';
    } else if (type === 'selectPlan') {
      infoSetPopUp.type = 'selectPlan';
      infoSetPopUp.title = 'Acción requerida';
      infoSetPopUp.message =
        'El plan seleccionado no tiene propiedades disponibles, puede remover un auto del plan o comprar un plan nuevo para poder cubrir más autos.';
      infoSetPopUp.labelButton = 'Comprar plan';
      infoSetPopUp.labelButtonCancel = 'Editar plan';
    } else {
      infoSetPopUp.showModal = false;
    }
    setPopUp({ ...infoSetPopUp });
  };
  const handleStepProperties = (item: number) => {
    if (item > stepProperties.length) {
      stepProperties.push({
        item,
        active: stepProperties.length > 0 ? false : true,
      });
    } else {
      stepProperties.splice(item);
      if (item < (atributes as ITypeProperty).itemActive && item > 0) {
        handleItemActive(item);
      }
      if (item === 0 && atributes) {
        atributes.itemActive = 1;
        setAtributes({ ...atributes });
      }
    }
    setStepProperties([...stepProperties]);
    handleFormControls(
      atributes?.propiedades.data as IAttributesPropertyData[],
      item
    );
  };
  const handleItemActive = (item: number) => {
    stepProperties.map((step) => {
      step.active = false;
    });
    stepProperties[item - 1].active = true;
    setStepProperties([...stepProperties]);
    if (atributes) {
      atributes.itemActive = item;
      setAtributes({ ...atributes });
    }
  };
  const backIndex = () => {
    formAddProperty.setErrors({});
    const itemActive = atributes?.itemActive as number;
    handleItemActive(itemActive - 1);
  };
  const handleFormControls = (
    data: IAttributesPropertyData[],
    item: number
  ) => {
    data.forEach((element) => {
      if (item > stepProperties.length) {
        delete formAddProperty.values[
          `${atributes?.name}__${element.attributes.name}__${element.id}__${
            item + 1
          }`
        ];
      } else {
        formAddProperty.values[
          `${atributes?.name}__${element.attributes.name}__${element.id}__${
            item + 1
          }`
        ] = '';
      }
    });
  };
  const handleClosePopUp = () => {
    handlePopUp(undefined);
    if (popUp.type === 'selectPlan') {
      navigate(`/edit-plan/${popUp.planId}`);
    } else {
      navigate('/settings/properties');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formAddPropertyValidate = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    if (!values.userProduct && typeof values.userProduct !== 'number') {
      errors.userProduct = 'Seleccione un plan';
    }
    atributes?.propiedades.data.forEach((atribute) => {
      if (atribute.attributes.required) {
        if (
          !values[
            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
          ]
        ) {
          errors[
            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
          ] = 'Campo requerido';
        } else {
          if (
            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`.includes(
              'Año'
            ) &&
            Number(
              values[
                `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
              ]
            ) < year
          ) {
            errors[
              `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
            ] = `El año debe ser mayor a ${year}`;
          }
        }
      }
    });
    return errors;
  };
  const formAddProperty = useFormik({
    initialValues: formAddPropertyInitialValues,
    validate: formAddPropertyValidate,
    onSubmit: async () => {
      try {
        const dataRequest: IAddPropertiy[] = [];
        const dataAtributes: IAtribute[] = [];
        stepProperties.forEach((step, idexStep: number) => {
          atributes?.propiedades.data.forEach(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (atribute, indexAtribute: number) => {
              const attributeValue =
                atribute.attributes.name === 'Placa'
                  ? formAddProperty.values[
                      `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${step.item}`
                    ].toUpperCase()
                  : formAddProperty.values[
                      `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${step.item}`
                    ];
              dataAtributes[indexAtribute] = {
                valor: attributeValue,
                status: true,
                propiedad: atribute.id,
                user_product: formAddProperty.values.userProduct,
                product_type_id: atributes.id,
              };
            }
          );
          dataRequest[idexStep] = {
            atributos: [...dataAtributes],
          };
        });
        if (atributes?.itemActive === stepProperties.length) {
          await createProductProperties(
            user.uid,
            {
              data: [...dataRequest],
            },
            userImpersonationId
          );
          navigate('/settings/properties');
        } else {
          handleItemActive((atributes?.itemActive as number) + 1);
        }
      } catch (error) {
        // TODO: Notificar error
      } finally {
        formAddProperty.setSubmitting(false);
      }
    },
  });
  const handleSelectOption = (value: number | string, options?: any) => {
    const { info, id } = (options || planesOptions).find(
      (p: any) => p.value === value
    ) as IOptionSelect;

    if (info) {
      const optionMaxCount = +(info as string).split(' ')[0];
      if (optionMaxCount <= 0) {
        setCount((count) => count);
        handlePopUp('selectPlan', id);
        return;
      }
      setMaximumNumberPropertiesAdd(optionMaxCount);
      if (stepProperties.length > 0) {
        handleStepProperties(optionMaxCount);
        setCount(optionMaxCount);
      }
    } else {
      setCount((count) => count);
      setMaximumNumberPropertiesAdd(10);
    }
    formAddProperty.setFieldValue('userProduct', value);
  };

  const validateInput = async (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>,
    typeEvent: 'blur' | 'change',
    formName: string
  ) => {
    const { value } = event.target;
    formAddProperty.setFieldValue(formName, value, true);
    if (!value) {
      formAddProperty.handleBlur(event);
    } else {
      if (
        formName.includes('Año') &&
        value.length >= 4 &&
        Number(value) <= 1990
      ) {
        await formAddProperty.setTouched({ [formName]: true }, false);
        formAddProperty.setErrors({
          [formName]: 'El año debe ser mayor a 1990',
        });
      } else {
        if (typeEvent === 'blur') {
          formAddProperty.handleBlur(event);
        } else {
          formAddProperty.handleChange(event);
        }
      }
    }
  };

  return (
    <>
      <HeaderWithSteps
        navbarTemplate="light"
        steps={step}
        onClick={() => handlePopUp('exitsProcess')}
        notProgress={true}
      />
      <section className="add__property__container">
        {isLoading ? (
          <AddPropertySkeleton></AddPropertySkeleton>
        ) : (
          <div className="add__property__content">
            <img
              className="add__property__icon"
              src={getPropertyInfo(typeProperty).image}
              alt="Tipo de propiedad"
            />
            <Select
              WrapperClassName="grid__span-6"
              id="add-property-select"
              placeholder="Seleccione cobertura"
              value={formAddProperty.values.userProduct}
              name="userProduct"
              onClick={handleSelectOption}
              options={planesOptions}
              isRequired={true}
              errorSelect={formAddProperty.errors.userProduct as string}
              onBlur={formAddProperty.handleBlur}
              isTouched={formAddProperty.touched.userProduct as boolean}
            />
            <p className="add__property__text m--small">
              {getPropertyInfo(typeProperty).message}
            </p>
            <ItemAddProperty
              id="add"
              template="primary"
              maxItems={maximumNumberPropertiesAdd}
              isDisabledButtonPlus={
                stepProperties.length >= maximumNumberPropertiesAdd
              }
              isDisabledButtonLess={stepProperties.length <= 1}
              isDisabled={
                !formAddProperty.values.userProduct &&
                formAddProperty.values.userProduct !== 0
              }
              type={{ ...appConstants }[typeProperty] as TypeProperty}
              onClick={handleStepProperties}
              count={count}
              branch={branch}
            />
            {stepProperties.length > 0 && (
              <>
                <p className="add__property__text">
                  {getPropertyInfo(typeProperty).description}
                </p>
                <form
                  className="add__property__form grid rg-0 grid__col-6"
                  onSubmit={formAddProperty.handleSubmit}
                  noValidate
                >
                  <div className="add__property__form__quantity grid__span-6">
                    {stepProperties.map(
                      (
                        item: {
                          item: number;
                          active: boolean;
                        },
                        index: number
                      ) => (
                        <ButtonIndexProperty
                          key={`${item.item}-${index}`}
                          index={item.item.toString()}
                          isDisabled={item.item !== atributes?.itemActive}
                        ></ButtonIndexProperty>
                      )
                    )}
                  </div>
                  {atributes?.propiedades.data.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (atribute, index: number) => (
                      <Input
                        key={`input__atribute-${index}`}
                        WrapperClassName={
                          atribute.attributes.large
                            ? 'grid__span-6'
                            : 'grid__span-3'
                        }
                        label={atribute.attributes.name}
                        id={`input__atribute__${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`}
                        type={atribute.attributes.type as TypeInput}
                        name={`${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`}
                        onChange={(e) =>
                          validateInput(
                            e,
                            'change',
                            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
                          )
                        }
                        onBlur={(e) =>
                          validateInput(
                            e,
                            'blur',
                            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
                          )
                        }
                        value={
                          formAddProperty.values[
                            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
                          ] || ''
                        }
                        isTouched={
                          formAddProperty.touched[
                            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
                          ] as boolean
                        }
                        errorInput={
                          formAddProperty.errors[
                            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
                          ] as string
                        }
                        errorLabel={
                          formAddProperty.errors[
                            `${atributes.name}__${atribute.attributes.name}__${atribute.id}__${atributes.itemActive}`
                          ] as string
                        }
                        isRequired={atribute.attributes.required as boolean}
                        form={formAddProperty}
                        maxLength={atribute.attributes.length as number}
                        textTransform={
                          atribute.attributes.name === 'Placa' ? true : false
                        }
                      />
                    )
                  )}
                </form>
              </>
            )}
          </div>
        )}
        <div className="add__property__content">
          <div style={{ marginTop: '51px' }}></div>
          <ContactCard />
          {stepProperties &&
            stepProperties.length > 0 &&
            !stepProperties[0]?.active && (
              <Button
                color="primary"
                size="medium"
                template="primary"
                typeStyle="rounded"
                className="ghost__button mt-30"
                type="submit"
                onClick={backIndex}
              >
                regresar
              </Button>
            )}
          <Button
            styleInLine={{ marginTop: '2rem' }}
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="submit"
            label={
              atributes?.itemActive === stepProperties.length
                ? 'guardar y salir'
                : 'continuar'
            }
            isDisabled={
              !formAddProperty.isValid ||
              Object.keys(formAddProperty.touched).length === 0 ||
              stepProperties.length === 0
            }
            isLoading={formAddProperty.isSubmitting}
            onClick={formAddProperty.handleSubmit}
          ></Button>
        </div>
      </section>
      {popUp.showModal && (
        <PopupExistProcess
          title={popUp.title}
          message={popUp.message}
          labelButton={popUp.labelButton}
          labelButtonCancel={popUp.labelButtonCancel}
          type="warning"
          closePopUp={() => handlePopUp(undefined)}
          exitProcess={handleClosePopUp}
        />
      )}
    </>
  );
};
