import { ButtonMoreOptions, Card, Chips, ItemStatus } from 'components';
import { PaymentGatewayLink } from '../PaymentGatewayLink/PaymentGatewayLink';
import './Item-product.scss';

interface ItemProductProps {
  productId: number;
  productTitle: string;
  price: number;
  quantity: number;
  status: 'successful' | 'error';
  customerStripeId: string;
  providerProcessor: string;
}

const messageStatus = {
  successful: 'Activa',
  error: 'Inactiva',
};

export const ItemProduct = ({
  productId,
  productTitle,
  price,
  status,
  quantity,
  customerStripeId,
  providerProcessor,
}: ItemProductProps) => {
  const handleClickEdit = () => {
    alert('Editar');
  };

  return (
    <div className="item__product__container">
      <Card>
        <div className="item__product__content">
          <div className="item__product__title">
            <span className="title--bold row">
              <span>{productTitle}</span>
            </span>
            <div className="item__product__more__options">
              <ButtonMoreOptions
                id={productId}
                testid={`test_${productId}`}
                onClickEdit={handleClickEdit}
              />
            </div>
          </div>
          <div className="item__product__detail">{`Precio $${price}`}</div>
          <div className="item__product__status">
            <div className="item__product__chips-left">
              <Chips label={`# Vehículos ${quantity}`} />
              <Chips label={'ADD ON'} />
            </div>
            <div className="item__product__chips-right">
              <div className="item__product__icon-pasarela">
                <PaymentGatewayLink
                  customerStripeId={customerStripeId}
                  providerProcessor={providerProcessor}
                />
              </div>
              <ItemStatus
                status={status}
                message={messageStatus[status]}
              ></ItemStatus>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
