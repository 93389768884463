import { ICancelMoreOptions } from 'components/atoms/Button-more-options/Button-more-options';
import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import { IPropsServicesHistory } from 'components/organisms/Services-history/Services-history';
import { config } from 'config/constants';
import { IAppReducer, IDetailStep } from 'models';
import { TypeState } from 'models/app.interface';
import { IUserSubscription } from 'pages/Edit-Property/models/Edit-Property.interface';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getNextPayment,
  getUserPayments,
  renewalSubscriptionService,
} from 'shared/services/payments';
import {
  addAddon,
  getPlanById,
  getUserAllPlans,
  getUserPlans,
  removePlanFromUser,
  tryToCancelSubscription,
} from 'shared/services/plans';
import {
  deleteUserCard,
  getUserCards,
  updateUserCard,
} from 'shared/services/user';
import { getContactInfo } from 'utils/connect-info.utils';
import { formatDateWithHour } from 'utils/date.utils';
import { formatPrice } from 'utils/payments.utils';
import { Card } from '../models/Card.interface';
import { InfoChangePlanAddOns } from '../models/Payments.interface';

export const usePaymentsAndBilling = () => {
  const navigate = useNavigate();
  const connectWebAnchor = useRef<HTMLAnchorElement>(null);
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const userImpersonationId = user?.agentAuthData?.userId ?? null;

  const branchReducer = useSelector(
    (state: IAppReducer) => state.branchReducer
  );

  const steps: IDetailStep[] = [
    {
      description: 'ajustes',
      state: 'active',
      link: '/settings',
      permissionToNavigate: true,
    },
    {
      description: 'planes y pagos',
      state: 'active',
      link: '/settings/payments',
      permissionToNavigate: false,
    },
  ];
  const [userPlans, setUserPlans] =
    useState<IUserSubscription[] | undefined | null>(undefined);
  const [userPlanSelected, setUserPlanSelected] =
    useState<IUserSubscription | undefined | null>(undefined);
  const [planSelectedForAddons, setPlanSelectedForAddons] =
    useState<IUserSubscription | undefined | null>(undefined);
  const [userCards, setUserCards] = useState<Card[] | undefined>(undefined);
  const [isLoadingCards, setIsLoadingCards] = useState<boolean>(false);
  const [isPlanAddOnUpdate, setIsPlanAddOnUpdate] = useState(false);
  const [infoAddOns, setInfoAddOns] = useState<InfoChangePlanAddOns>();
  const [isOpenPopUpInformative, setIsOpenPopUpInformative] = useState(false);
  const [isLoadingRenewalSubscription, setIsLoadingRenewalSubscription] =
    useState<{
      isOpen: boolean;
      loading: boolean;
      type: TypeState;
      title: string;
      message: string;
    }>({
      isOpen: false,
      loading: false,
      type: 'warning',
      title: 'Renovando plan',
      message: 'Ocurrió un error, inténtalo mas tarde',
    });
  const [payments, setPayments] =
    useState<IPropsServicesHistory['data'] | undefined | null>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [infoPopUp, setInfoPopUp] = useState<any>(undefined);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [showPopUAddAddOns, setShowPopUAddAddOns] = useState<boolean>(false);
  const [planAddAddOns, setPlanAddAddOns] = useState<IUserSubscription[]>();
  const [coupon, setCoupon] = useState<ICouponInformation | undefined>();

  const [isLoadingTryToCancel, setIsLoadingTryToCancel] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [infoNextPayment, setInfoNextPayment] = useState<any>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [amountToRefund, setAmountToRefund] = useState<any>(null);
  const [showModalEditPaymentMethod, setShowModalEditPaymentMethod] =
    useState('');
  const [isLoadingEdit, setIsLoadingEdit] = useState<{
    loading: boolean;
    error: string | undefined;
  }>({
    loading: false,
    error: '',
  });

  useEffect(() => {
    (async () => {
      getPlans();
    })();
  }, [getUserAllPlans]);

  useEffect(() => {
    (async () => {
      setIsLoadingCards(true);
      try {
        const cards = await getUserCards(user.uid);
        setUserCards(cards);
      } catch (error) {
        console.error('Error getting cards', error);
        setUserCards(undefined);
      } finally {
        setIsLoadingCards(false);
      }
    })();
  }, [getUserCards]);

  useEffect(() => {
    (async () => {
      getPayments();
    })();
  }, [getUserPayments]);

  useEffect(() => {
    (async () => {
      try {
        const nextPayment = await getNextPayment(user.uid);
        setInfoNextPayment(nextPayment);
      } catch (error) {
        console.log('Error getting getNextPayment 🔴', error);
      }
    })();
  }, [getUserPlans, setPayments]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mappingPayments = (responsePayments: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = responsePayments.map((p: any) => {
      return {
        name: p.attributes.detail, //p.attributes.metadata.lines.data[0].plan.nickname,
        number: p.attributes.provider_reference_id || p.attributes.referenceId,
        description: p.attributes.detail, //p.attributes.metadata.lines.data[0].description,
        total: p.attributes.total,
        date: formatDateWithHour(p.attributes.date), //,formarDate(p.attributes.metadata.lines.data[0].period.start),
        typeService: p.attributes.service_type.data.attributes.name, //p.attributes.metadata.lines.data[0].type,
        status: p.attributes.payment_status.data.attributes.class_alert,
        message: p.attributes.payment_status.data.attributes.name,
        couponName: p.attributes.coupon_code,
        couponAmount: p.attributes.coupon_amount,
        hasDiscount: p.attributes.subscriptions.data[0].attributes.has_trial,
        planName:
          p.attributes.subscriptions.data[0].attributes.plan_id.data.attributes
            .alias_title || '',
      };
    }, []);
    return data;
  };
  const getPayments = async () => {
    try {
      const responsePayments = await getUserPayments(user.uid);
      setPayments(mappingPayments(responsePayments));
    } catch (error) {
      setPayments(null);
    }
  };
  const getPlans = async () => {
    try {
      const responsePlans = await getUserAllPlans(user.uid, user.agentId);
      setUserPlans(responsePlans);
    } catch (error) {
      setUserPlans(null);
    }
  };

  const handleCancelAndPopUp = async ({ id, isRefund }: ICancelMoreOptions) => {
    if (isLoadingTryToCancel) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let planSelected: any = undefined;
    if (id) {
      planSelected =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (userPlans && userPlans.find((plan: any) => plan.id == id)) ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userCards?.find((card: any) => card.id == id);
      if (planSelected.attributes) {
        setIsLoadingTryToCancel(true);
        let tryToCancel;
        if (isRefund) {
          tryToCancel = await tryToCancelSubscription(
            user.uid,
            id,
            planSelected.attributes.has_trial
          );
          planSelected.attributes.isRefund = true;
          setAmountToRefund(tryToCancel);
        } else if (typeof isRefund === 'boolean') {
          tryToCancel = 0;
          planSelected.attributes.isRefund = false;
          setAmountToRefund(tryToCancel);
        }
      }
      setShowPopUp(true);
    } else {
      setShowPopUp(false);
    }
    setIsLoadingTryToCancel(false);
    setInfoPopUp(planSelected);
  };

  const handleCancel = async ({ id, isRefund }: ICancelMoreOptions) => {
    handleCancelAndPopUp({ id, isRefund });
  };

  const handlePopUp = async (id?: number | string) => {
    handleCancelAndPopUp({ id });
  };

  const closePopUpAddon = async () => {
    setShowPopUAddAddOns(false);
  };

  const addAddOns = async (id?: number | string) => {
    if (isLoadingTryToCancel) return;
    setPlanAddAddOns(undefined);
    let planSelected: IUserSubscription | undefined = undefined;
    if (id && userPlans) {
      planSelected = userPlans.find(
        (plan) => plan.id == id
      ) as IUserSubscription;

      setPlanSelectedForAddons(planSelected);
    }
    setShowPopUAddAddOns(true);
    const responsePlans: IUserSubscription[] = await getPlanById(
      config.default_plans.addon as string,
      branchReducer.branch
    );
    setPlanAddAddOns(responsePlans);
    if (!infoAddOns) {
      const dataPlanAddOns = await addAddon({
        email: user.email,
        country_id: branchReducer.country_id.toString(),
        planId: responsePlans[0].id as number,
        productId: responsePlans[0].attributes.productos.data[0].id.toString(),
        subscriptionId: planSelected?.id as number,
        uid: user.uid,
        process: false,
        couponCode: coupon?.coupon_code ?? null,
        userImpersonationId,
      });
      setInfoAddOns({
        price: dataPlanAddOns.amountActualSubscription,
        newPrice: dataPlanAddOns.total,
        originalNewPrice: dataPlanAddOns.total,
        deference: dataPlanAddOns.amountProrated,
        originalDeference: dataPlanAddOns.amountProrated,
      });
    }
  };
  const deleteItemFromUser = async () => {
    try {
      setIsLoading(true);
      if (infoPopUp.attributes) {
        const isRefund =
          infoPopUp.attributes.isRefund || infoPopUp.attributes.has_trial;
        await removePlanFromUser(
          user.uid,
          infoPopUp.id as number,
          isRefund,
          userImpersonationId
        );
      } else {
        const cardIfForDelete = infoPopUp.card_id
          ? infoPopUp.card_id
          : infoPopUp.id;
        await deleteUserCard(user.uid, cardIfForDelete);
      }
      setShowPopUp(false);
      if (infoPopUp.attributes) {
        const responsePlans = await getUserPlans(user.uid);
        setUserPlans(responsePlans);
      } else {
        setIsLoadingCards(true);
        try {
          const cards = await getUserCards(user.uid);
          setUserCards(cards);
        } catch (error) {
          console.error('Error getting cards', error);
          setUserCards(undefined);
        } finally {
          setIsLoadingCards(false);
        }
      }
    } catch (error: any) {
      handlePopUp();
      console.log('error al eliminar la propiedad', error);
      if (
        error?.response?.data?.error?.message?.indexOf('deleting card') > -1
      ) {
        alert('Error al eliminar la tarjeta');
      }
    } finally {
      setIsLoading(false);
    }
  };
  const formatPriceForCountry = (value: number): string => {
    return formatPrice(value, branchReducer.branch);
  };

  const showPopUpEditCardCredit = (card: Card) => {
    setShowModalEditPaymentMethod(card.id);
  };
  const goToEditCardCredit = () => {
    setIsLoadingEdit({ loading: true, error: undefined });
    updateUserCard(user.uid, showModalEditPaymentMethod)
      .then(async () => {
        setIsLoadingCards(true);
        try {
          const responseCards = await getUserCards(user.uid);
          setUserCards(responseCards);
          setIsLoadingEdit({ loading: true, error: undefined });
        } catch (error) {
          console.error('Error getting cards', error);
          setIsLoadingEdit({
            loading: false,
            error: 'Error al actualizar metodo de pago',
          });
          setUserCards(undefined);
        } finally {
          setIsLoadingCards(false);
        }
      })
      .catch(() =>
        setIsLoadingEdit({
          loading: false,
          error: 'Error al actualizar metodo de pago',
        })
      )
      .finally(() => {
        setShowModalEditPaymentMethod('');
      });
  };
  const goToAddPlan = () => {
    const defaultPlans = user.plansId;
    navigate('/buy-plan?planId=' + defaultPlans);
  };
  const goToWhatsApp = () => {
    if (connectWebAnchor.current) {
      connectWebAnchor.current.href = `https://wa.me/${
        getContactInfo(branchReducer.branch).phone
      }`;
      connectWebAnchor.current.click();
    }
  };
  const handleRenewalSubscription = async (subscriptionId: string) => {
    try {
      setIsLoadingRenewalSubscription({
        isOpen: true,
        loading: true,
        type: 'warning',
        title: 'Renovando plan',
        message: ' Esto tomara un momento',
      });
      // Note: Workaround - La página se recarga después de 10 segundos.
      // Esto es temporal; lo ideal es manejar correctamente la respuesta de renewalSubscriptionService
      // en el servidor para evitar depender de un reload.
      await renewalSubscriptionService(subscriptionId, userImpersonationId);
      setTimeout(() => {
        window.location.reload();
      }, 10000);
      // Note: Uncomment when renewalSubscriptionService be correct
      // await getPlans();
      // setIsLoadingRenewalSubscription({
      //   isOpen: true,
      //   loading: false,
      //   type: 'successful',
      //   title: 'Éxito',
      //   message: 'Plan renovado con éxito',
      // });
    } catch (error) {
      setIsLoadingRenewalSubscription({
        isOpen: true,
        loading: false,
        type: 'error',
        title: 'Error',
        message: 'Ocurrió un error, inténtalo mas tarde',
      });
    }
  };
  const handlePopUPDetailPayment = (id: number | string) => {
    const planSelected = userPlans?.find(
      (plan: IUserSubscription) => plan.id === id
    );
    console.log('planSelected', planSelected);
    setUserPlanSelected(planSelected);
    setIsOpenPopUpInformative(true);
  };

  const changePlanToAddOns = async () => {
    try {
      await addAddon({
        email: user.email,
        country_id: branchReducer.country_id.toString(),
        planId: (planAddAddOns as IUserSubscription[])[0].id as number,
        productId: (
          planAddAddOns as IUserSubscription[]
        )[0].attributes.productos.data[0].id.toString(),
        subscriptionId: planSelectedForAddons?.id as number,
        uid: user.uid,
        process: true,
        couponCode: coupon?.coupon_code ?? null,
        userImpersonationId,
      });
      setUserPlans(undefined);
      setPayments(undefined);
      await getPlans();
      await getPayments();
      setIsPlanAddOnUpdate(true);
      window.scrollTo({ behavior: 'smooth', top: 0 });
    } catch (error) {
      console.log('Error 🚨', error);
    }
  };
  const setInfoSubscription = (
    subscriptionId: string,
    planId: number | string
  ) => {
    navigate(`/edit-plan/${planId}/${subscriptionId}`);
  };
  const goToPlanDetail = (id: number | string) => {
    navigate(`/plan-detail/${id}`);
  };

  return {
    branch: branchReducer.branch,
    steps,
    navigate,
    userPlans,
    infoNextPayment,
    formatPriceForCountry,
    user,
    goToAddPlan,
    infoPopUp,
    goToWhatsApp,
    handlePopUPDetailPayment,
    handlePopUp,
    handleCancel,
    addAddOns,
    handleRenewalSubscription,
    isLoadingCards,
    userCards,
    payments,
    amountToRefund,
    deleteItemFromUser,
    isLoading,
    showModalEditPaymentMethod,
    showPopUp,
    setShowModalEditPaymentMethod,
    goToEditCardCredit,
    showPopUpEditCardCredit,
    isLoadingEdit,
    isOpenPopUpInformative,
    setIsOpenPopUpInformative,
    userPlanSelected,
    isLoadingRenewalSubscription,
    setIsLoadingRenewalSubscription,
    connectWebAnchor,
    showPopUAddAddOns,
    setShowPopUAddAddOns,
    closePopUpAddon,
    planAddAddOns,
    changePlanToAddOns,
    setInfoSubscription,
    goToPlanDetail,
    isPlanAddOnUpdate,
    infoAddOns,
    setInfoAddOns,
    coupon,
    setCoupon,
    planSelectedForAddons,
  };
};
