import {
  ButtonAddItem,
  GenericSectionError,
  LoadingSkeleton,
  TitleSection,
} from 'components';
import { ItemProduct } from 'components/molecules/Item-product/Item-product';
import { useNavigate } from 'react-router';
import { ProductsDetailsView } from 'shared/services/user-subscription';

interface ProductsListProps {
  products: ProductsDetailsView[];
  error: boolean;
  loading: boolean;
  customerStripeId: string;
  providerProcessor: string;
}

export const ProductsList = ({
  products,
  error,
  loading,
  customerStripeId,
  providerProcessor,
}: ProductsListProps) => {
  const navigate = useNavigate();

  return (
    <>
      <TitleSection title="Productos">
        <ButtonAddItem
          testid="btn_list_products_new_product"
          label="Agregar"
          onClick={() => navigate('/add-payment-method')}
          template="primary"
        />
      </TitleSection>
      <div className="subscription_details__content">
        <div className="subscription_details__content__items">
          {error ? (
            <GenericSectionError template="tertiary" />
          ) : loading ? (
            <LoadingSkeleton count={2} />
          ) : !products?.length ? (
            <span>No tienes products</span>
          ) : (
            products.map((product) => (
              <ItemProduct
                key={product.productId}
                providerProcessor={providerProcessor}
                customerStripeId={customerStripeId}
                productId={product.productId}
                productTitle={product.productName}
                quantity={product.quantity}
                price={product.amount}
                status={product.status}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
