import { HeaderWithAlert } from 'components';
import { SubscriptionDetailsHeader } from 'components/molecules/Subscription-details-header/Subscription-details-header';
import { SubscriptionDetailsStatus } from 'components/molecules/Subscription-details-status/Subscription-details-status';
import { useParams } from 'react-router-dom';
import { ProductsList } from './components/ProductsList';
import { useSubscriptionDetails } from './hooks/SubscriptionDetails.hook';
import './SubscriptionDetails.scss';

export const SubscriptionDetails = () => {
  const { subId } = useParams();
  const { steps, navigate, sub, error, loading } =
    useSubscriptionDetails(subId);

  return (
    <>
      <HeaderWithAlert
        steps={steps}
        onClick={() => navigate('/settings')}
      ></HeaderWithAlert>
      <SubscriptionDetailsHeader
        title={'detalles de suscripción'}
        subtitle={sub.id}
      />
      <main
        className="subscription_details__container container__generic"
        role="main"
        aria-label="Detalles de suscripción"
      >
        <section
          className="subscription_details__container__section"
          aria-label="Suscripción estado"
        >
          <SubscriptionDetailsStatus
            dateInit={sub.dateInit}
            dateExpire={sub.dateExpire}
            amountSub={sub.amount}
            typePeriodicity={sub.typePeriodicity}
          />
        </section>
        <section
          className="subscription_details__container__section"
          aria-label="Lista de productos"
        >
          <ProductsList
            providerProcessor={sub.providerProcessor}
            customerStripeId={sub.customerStripeId}
            products={sub.products}
            error={error}
            loading={loading}
          />
        </section>
      </main>
    </>
  );
};
