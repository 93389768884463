import './Subscription-details-status.scss';

interface SubscriptionDetailsStatusProps {
  dateInit: string;
  typePeriodicity: string;
  amountSub: number;
  dateExpire: string;
}

export const SubscriptionDetailsStatus = ({
  dateInit,
  dateExpire,
  typePeriodicity,
  amountSub,
}: SubscriptionDetailsStatusProps) => {
  return (
    <div className="sub-details-status">
      <div>
        <p className="sub-details-status__label">Fecha inicio</p>
        <p className="sub-details-status__value">
          {dateInit} - {typePeriodicity?.toLocaleUpperCase()}
        </p>
      </div>
      <div>
        <p className="sub-details-status__label">Siguiente cobro</p>
        <p className="sub-details-status__value sub-details-status__value--highlight">
          ${amountSub} - {dateExpire}
        </p>
      </div>
    </div>
  );
};
