import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { Images } from 'assets/images/images.index';
import { ButtonAddItem } from 'components/atoms/Button-add-item/Button-add-item';
import { Button } from 'components/atoms/Button/Button';
import { DetailAccordion } from 'components/atoms/Detail-accordion/Detail-accordion';
import { Divider } from 'components/atoms/Divider/Divider';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { ItemCreditCard } from 'components/molecules/Item-credit-card/Item-credit-card';
import { PaymentMethodDlocal } from 'components/molecules/Payment-method-dlocal/Payment-method-dlocal';
import { IInputFocusEvent } from 'components/molecules/Payment-method-dlocal/models/payment-method-dlocal.interface';
import { PaymentMethodStripe } from 'components/molecules/Payment-method-stripe/Payment-method-stripe';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { CartCheckoutSkeleton } from 'components/skeleton/organisms/Cart-checkout-skeleton/Cart-checkout-skeleton';
import { CartCheckout } from 'components/templates/Cart-checkout/Cart-checkout';
import { config } from 'config/constants';
import { useFormik } from 'formik';
import * as Analytics from 'helpers/segment.helper';
import { IAppReducer } from 'models/reducer.interface';
import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
import { createOrderId } from 'pages/Checkout/services/checkout.service';
import {
  resetCheckoutInformation,
  setCheckoutInformation,
} from 'pages/Checkout/store/actions/checkout.types';
import { resetCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';
import { Card } from 'pages/Settings/pages/Payments-billing/models/Card.interface';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPlanById } from 'shared/services/plans';
import { getUserCards } from 'shared/services/user';
import { getCountry, getKeyStripe } from 'utils/branch.utils';
import { getContactInfo } from 'utils/connect-info.utils';
import { addSubscription } from '../../../../shared/services/users';
import './Payment-information.scss';
import {
  IFormErrorPaymentSubscription,
  IFormPaymentSubscription,
} from './models/Payment-subscription.interface';

export const Paymentinformation = (props: {
  handleStep?: (index: number) => void;
  link: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recaptcha, setCapcha] = useState<any | null>(null);
  const cartCheckoutRef =
    useRef<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      saveOrderInformation: () => any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sendOrderCompleted: (order: any) => any;
    }>(null);
  const dlocalRef = useRef(null);
  const stripeRef = useRef(null);
  const {
    checkoutReducer,
    authReducer,
    branchReducer: { branch, country_id },
  } = useSelector((state: IAppReducer) => state);
  const [stripeLoad, setStripeLoad] = useState<Stripe | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [products, setProducts] = useState<any>(null);
  const [orderId, setOrderId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errorPayment, setErrorPayment] = useState<any>(null);
  const [userCards, setUserCards] = useState<Card[] | undefined>(undefined);
  const [isNewCreditCard, setIsNewCreditCard] = useState<boolean>(false);
  const [popUp, setPopUp] = useState<{
    showModal: boolean;
    title: string;
    message: string;
    labelButton: string;
  }>({
    showModal: false,
    title: '¡Alto!',
    message:
      '<strong>Está por dejar el proceso incompleto.</strong> Recuerda que al al dejarlo incompleto deberá iniciarlo nuevamente cuando regreses.',
    labelButton: 'Continuar',
  });
  const [phoneBranch, setPhoneBranch] = useState<string>('');
  const contentEl = useRef<HTMLDivElement>(null);

  const {
    authReducer: user,
    createAccountReducer,
    branchReducer,
  } = useSelector((state: IAppReducer) => state);

  useLayoutEffect(() => {
    const loadStripeElement = async () => {
      const stripeKey = getKeyStripe(branch);
      const stripe = (await loadStripe(stripeKey)) as Stripe;
      setStripeLoad(stripe);
    };
    loadStripeElement();
    props.handleStep && props.handleStep(1);
  }, [setStripeLoad]);

  useLayoutEffect(() => {
    const phone = getContactInfo(branchReducer.branch).phone;
    setPhoneBranch(phone);
  }, [branchReducer.branch]);

  useEffect(() => {
    (async () => {
      const dataCheckoutStepVviewed = {
        step: 3,
        order_id: checkoutReducer.order?.id as string,
      };
      Analytics.checkoutStepVviewed(dataCheckoutStepVviewed);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const planIdRequest = user.plansId
        ? user.plansId
        : createAccountReducer.plan_id;

      const responsePlans: IProduct[] = await getPlanById(
        planIdRequest,
        branch
      );
      const responsePlansRoad = responsePlans.filter(
        (plan) =>
          plan.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') >=
          0
      );
      const responsePlansOther = responsePlans.filter(
        (plan) =>
          plan.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') <
          0
      );
      const responsePlanInformation = [
        ...responsePlansRoad,
        ...responsePlansOther,
      ];
      setProducts(responsePlanInformation);
      const ordenId = await createOrderId({
        email: '',
        phone: '',
        country: Number(getCountry(branch)),
        plans: planIdRequest,
        raw_data: '',
      });
      setOrderId(ordenId);
    })();
  }, [branch]);

  useEffect(() => {
    (async () => {
      if (authReducer.uid) {
        try {
          const cards = await getUserCards(authReducer.uid);
          setUserCards(cards);
          const cardPrincipal = cards.find((card: Card) => card.is_principal);
          if (cardPrincipal) handleSelectCard(cardPrincipal.id);
        } catch (error) {
          console.error('Error getting cards', error);
          setUserCards(undefined);
        }
      } else {
        setUserCards(undefined);
      }
    })();
  }, [setUserCards]);

  const validateCaptcha = async () => {
    try {
      await recaptcha.execute();
      const token = recaptcha.getResponse();
      return token;
    } catch (error) {
      console.log('error', error);
    }
  };

  const newPaymentMethod = () => {
    formPaymentSubscription.setFieldValue('cardId', '');
    setIsNewCreditCard((isNewCreditCard) => !isNewCreditCard);
    contentEl?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const formPaymentSubscriptionInitialValues: IFormPaymentSubscription = {
    uuid: authReducer.uid,
    plan_id: '',
    card_token: '',
    coupon: '',
    country_id: country_id as number,
    agentId: '',
    orderId: '',
    card_name: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    cardId: '',
  };

  const formPaymentSubscriptionValidate = (
    values: IFormPaymentSubscription
  ) => {
    const errors: IFormErrorPaymentSubscription = {};

    if (!values.uuid) {
      errors.uuid = 'Este campo es requerido';
    }
    if (isNewCreditCard) {
      if (!values.card_name) {
        errors.card_name = 'Este campo es requerido';
      }
      if (!values.cardNumber) {
        errors.cardNumber = 'Este campo es requerido';
      }
      if (!values.cardExpiry) {
        errors.cardExpiry = 'Este campo es requerido';
      }
      if (!values.cardCvc) {
        errors.cardCvc = 'Este campo es requerido';
      }
    } else {
      if (!values.cardId) {
        errors.cardId = 'Este campo es requerido';
      }
    }
    return errors;
  };

  const formPaymentSubscription = useFormik({
    initialValues: formPaymentSubscriptionInitialValues,
    validate: formPaymentSubscriptionValidate,
    onSubmit: async (values: IFormPaymentSubscription) => {
      try {
        const dataCartCheckout =
          cartCheckoutRef.current?.saveOrderInformation();
        setErrorPayment(null);
        if (isNewCreditCard) {
          if (
            branch === 'pr' ||
            products[0].attributes?.company?.data?.attributes?.payment_provider
              .data.id === 1 ||
            !products[0].attributes?.company?.data?.attributes?.payment_provider
              .data.id
          ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values.card_token = await (
              stripeRef?.current as any
            ).getTokenCard();
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values.card_token = await (
              dlocalRef?.current as any
            ).getTokenCard();
          }
        }
        const tokenCaptcha = await validateCaptcha();
        const dataRequestsubscriptionAdded = {
          email: authReducer.email,
          orderId,
          uuid: values.uuid,
          plan_id: dataCartCheckout.order?.dataProducts.plan_id as string,
          coupon:
            dataCartCheckout.order?.dataOrderCompleted.coupon?.coupon_code,
          card_name: values.card_name ?? '',
          card_token: isNewCreditCard ? values.card_token : undefined,
          card_id: !isNewCreditCard
            ? formPaymentSubscription.values.cardId
            : undefined,
          country_id: country_id as number,
          agentId: user.agentId,
          products: [...(dataCartCheckout.order?.dataProducts.products as [])],
          subTotalEmail: dataCartCheckout.resumen.subTotal,
          totalEmail: checkoutReducer.trialPeriod
            ? 0
            : dataCartCheckout.resumen.price,
          discountForPeriodicity: checkoutReducer.trialPeriod
            ? (dataCartCheckout.resumen.price as number)
            : (dataCartCheckout.resumen.discount as number) || 0,
          discountForCoupon:
            (dataCartCheckout.resumen.discountCoupon as number) || 0,
        };
        const userImpersonationId = user?.agentAuthData?.userId ?? null;
        const subscriptionAdded = await addSubscription(
          dataRequestsubscriptionAdded,
          tokenCaptcha,
          userImpersonationId
        );
        cartCheckoutRef.current?.sendOrderCompleted({
          ...dataCartCheckout,
          order_id: orderId,
        });
        const arrUserPlansAdded = subscriptionAdded.data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (p: { id: number; attributes: any }) => p.attributes.plan_id.data.id,
          []
        );
        dispatch(
          setCheckoutInformation({
            id_user_product: subscriptionAdded.data.id,
            referenceId: subscriptionAdded.data[0].attributes.referenceId,
            user_plans_added: arrUserPlansAdded,
          })
        );
        navigate(props.link);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('error', error);
        setErrorPayment(error);
        window.scrollTo({ behavior: 'smooth', top: 0 });
      } finally {
        formPaymentSubscription.setSubmitting(false);
      }
    },
  });

  const changeValueInputStripe = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | IInputFocusEvent
  ) => {
    let value = '';
    if (event.complete) {
      value = 'complete';
    }
    let element: 'cardCvc' | 'cardExpiry' | 'cardNumber';
    if ('elementType' in event) {
      element = event.elementType;
    } else {
      element = event.element;
    }
    formPaymentSubscription.setFieldValue(element, value);
    formPaymentSubscription.values[element] = value;
    if (
      formPaymentSubscription.values.cardNumber === 'complete' &&
      formPaymentSubscription.values.cardCvc === 'complete' &&
      formPaymentSubscription.values.cardExpiry === 'complete'
    ) {
      Analytics.paymentInfoEntered(checkoutReducer.order?.id as string);
    }
  };

  const handleSelectCard = (id: string | number) => {
    formPaymentSubscription.setFieldValue('cardId', id);
    setIsNewCreditCard(false);
  };

  const showInfoPopUp = () => {
    popUp.showModal = !popUp.showModal;
    setPopUp({ ...popUp });
  };

  const exitProcess = () => {
    dispatch(resetCheckoutInformation());
    dispatch(resetCreateAccountInformation());
    showInfoPopUp();
    navigate('/settings/payments');
  };

  return (
    <Elements stripe={stripeLoad}>
      <form
        className="buy__plan__container container__generic"
        onSubmit={formPaymentSubscription.handleSubmit}
        noValidate
      >
        {errorPayment && (
          <>
            <div className="payment__padding">
              <DetailAccordion tilte="ERROR EN EL PAGO" type="error">
                {errorPayment.response?.data?.error?.message !== 'Not Found'
                  ? errorPayment.response?.data?.error?.message
                  : ''}
                <br />
                Ocurrió un error con tú método de pago, verifica la información
                digitada. Si el error persiste comunícate con nosotros al{' '}
                <b>
                  <a
                    style={{ color: '#fff', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                    href={`https://wa.me/${phoneBranch}`}
                  >
                    {' '}
                    {phoneBranch}
                  </a>
                </b>
              </DetailAccordion>
            </div>
            <Divider classWrapper="payment__margin" type="bar" />
          </>
        )}
        <h3 className="summary__information__title payment__padding">
          Resumen orden:
        </h3>
        <div className="content__payment">
          {products ? (
            <CartCheckout
              branch={branch}
              planItemsQuantity={[]}
              allProducts={products}
              orderId={orderId}
              ref={cartCheckoutRef}
              templete="default"
              agentId={user.agentId ? user.agentId.toString() : ''}
              companyName={checkoutReducer.company || ''}
            ></CartCheckout>
          ) : (
            <CartCheckoutSkeleton />
          )}
        </div>
        <TitleWhitImage
          title="información <br />de pago"
          image={Images.paymentInfo}
          styleInLineImage={{ width: '4.25rem', height: '4.25rem' }}
        ></TitleWhitImage>
        <div>
          <h1 className="edit__plan__information__payment__title">Pago</h1>
          <p className="edit__plan__information__payment__description">
            Método de pago utilizado actualmente.
          </p>
          <p className="edit__plan__information__payment__description italic">
            Si deseas puedes seleccionar otro método de pago, el cual quedará
            como el método principal.
          </p>
          <ButtonAddItem
            testid="btn_edit_plan_info_new_payment_method"
            label={isNewCreditCard ? 'Volver' : 'Agregar método de pago'}
            onClick={newPaymentMethod}
            styleInLine={{ margin: '0 0 1.031rem auto' }}
            template="primary"
          />
        </div>
        {isNewCreditCard ? (
          <>
            <div ref={contentEl} style={{ marginTop: '1.875rem' }}></div>
            {branch === 'pr' ||
            products[0].attributes?.company?.data?.attributes?.payment_provider
              .data.id === 1 ||
            !products[0].attributes?.company?.data?.attributes?.payment_provider
              .data.id ? (
              <PaymentMethodStripe
                id="payment-informarion-number"
                inputNameLabel="Nombre del titular de la tarjeta"
                inputNameControl="card_name"
                handleChangeNameInput={formPaymentSubscription.handleChange}
                handleBlurNameInput={formPaymentSubscription.handleBlur}
                touchedNameInput={formPaymentSubscription.touched.card_name}
                errorNameInput={formPaymentSubscription.errors.card_name}
                valueNameInput={formPaymentSubscription.values.card_name}
                form={formPaymentSubscription}
                handleChangeStripeInput={changeValueInputStripe}
                ref={stripeRef}
              />
            ) : (
              <PaymentMethodDlocal
                inputNameLabel="Nombre en la tarjeta"
                inputNameControl="card_name"
                handleChangeNameInput={formPaymentSubscription.handleChange}
                handleBlurNameInput={formPaymentSubscription.handleBlur}
                touchedNameInput={formPaymentSubscription.touched.card_name}
                errorNameInput={formPaymentSubscription.errors.card_name}
                valueNameInput={formPaymentSubscription.values.card_name}
                form={formPaymentSubscription}
                handleChangeDlocalInput={changeValueInputStripe}
                country={branch as 'co' | 'cr'}
                ref={dlocalRef}
              ></PaymentMethodDlocal>
            )}
          </>
        ) : (
          userCards &&
          userCards.map((card, index: number) => (
            <ItemCreditCard
              key={`item__credit__card__${index}`}
              id={card.id}
              onClickSelect={handleSelectCard}
              lastDigits={card.last4}
              brand={card.brand}
              isPrincipal={card.is_principal}
              isSelected={formPaymentSubscription.values.cardId === card.id}
              expMonth={card.exp_month}
              expYear={card.exp_year}
            />
          ))
        )}
        <Button
          color="primary"
          size="medium"
          template="primary"
          typeStyle="rounded"
          className="success"
          type="button"
          label="realizar pago"
          onClick={formPaymentSubscription.handleSubmit}
          isDisabled={
            formPaymentSubscription.isSubmitting ||
            !formPaymentSubscription.isValid ||
            !formPaymentSubscription.dirty
          }
          isLoading={formPaymentSubscription.isSubmitting}
          testid="btn__buy__plan"
        ></Button>
      </form>
      {popUp.showModal && (
        <PopupExistProcess
          title={popUp.title}
          message={popUp.message}
          labelButton={popUp.labelButton}
          labelButtonCancel="Salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
      <Recaptcha
        ref={(ref: unknown) => setCapcha(ref)}
        sitekey={config.recaptchaKey}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onResolved={() => {}}
      />
    </Elements>
  );
};
