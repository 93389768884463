import {
  ButtonAddItem,
  GenericSectionError,
  ItemSubscription,
  LoadingSkeleton,
  TitleSection,
} from 'components';
import { useSubscriptions } from '../hooks/Subscriptions.hook';

export const SubscriptionsList = () => {
  const { navigate, subscriptions, error, loading } = useSubscriptions();

  return (
    <>
      <TitleSection title="suscripciones">
        <ButtonAddItem
          testid="btn_subscriptions_new_subscription"
          label="Agregar"
          onClick={() => navigate('/add-payment-method')}
          template="primary"
        />
      </TitleSection>
      <div className="subscriptions__content">
        <div className="subscriptions__content__items">
          {error ? (
            <GenericSectionError template="tertiary" />
          ) : loading ? (
            <LoadingSkeleton count={2} />
          ) : !subscriptions?.length ? (
            <span>No tienes suscripciones</span>
          ) : (
            subscriptions.map((sub) => (
              <ItemSubscription
                key={sub.id}
                id={sub.id}
                products={sub.products}
                price={sub.price}
                dateExpire={sub.dateExpire}
                typePeriodicity={sub.typePeriodicity}
                status={sub.status}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
