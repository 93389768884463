import { Images } from 'assets/images/images.index';
import { Input, TypeInput } from 'components/atoms/Input/Input';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { FormikValues, useFormik } from 'formik';
import { IAtributesProperties } from 'pages/Create-account/pages/Activate-benefits/models/Activate-benefits.interface';
import { TypeProperties } from 'pages/Settings/models/Properties.interface';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { getYear } from 'shared/constants/year.constants';
import { IDataAttributes } from '../../models/Create-properties.interface';

export const FormVehicles = forwardRef(function FormVehicles(
  props: {
    properties: IDataAttributes[] | IAtributesProperties[];
    typeProduct: string;
    quantity: number;
    branch: string;
    values?: FormikValues;
    productType:
      | TypeProperties.MOTORA
      | TypeProperties.MOTOCICLETA
      | TypeProperties.VEHICULOS
      | TypeProperties.AUTO;
    handleFormValidityChange?: (isValid: boolean) => void;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any
) {
  const formVehiclesRef = useRef(null);
  const quantity = Array(props.quantity).fill(0);
  const year = getYear(props.branch);
  const formVehiclesPropertiesInitialValues: FormikValues = {};
  const formVehiclesPropertiesValidate = (values: FormikValues) => {
    const errors: FormikValues = {};
    quantity.forEach((_, quantityIndex) => {
      props.properties.forEach((property) => {
        if (
          `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`.includes(
            'Año'
          ) &&
          Number(
            values[
              `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
            ]
          ) < year
        ) {
          errors[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ] = `El año debe ser mayor a ${year}`;
        }
        if (
          property.attributes.required &&
          !values[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ]
        ) {
          errors[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ] = 'Campo requerido';
        }
      });
    });
    return errors;
  };

  const formVehiclesProperties = useFormik({
    initialValues: formVehiclesPropertiesInitialValues,
    validate: formVehiclesPropertiesValidate,
    onSubmit: async (values) => {
      return values;
    },
  });

  useMemo(() => {
    formVehiclesProperties.setValues({ ...props.values });
  }, [props.values]);

  const isFormValid =
    formVehiclesProperties.isValid &&
    Object.keys(formVehiclesProperties.errors).length === 0;

  useMemo(() => {
    if (props.handleFormValidityChange) {
      props.handleFormValidityChange(isFormValid);
    }
  }, [isFormValid, props]);

  useImperativeHandle(ref, () => {
    return { getValues };
  });
  const getValues = (): FormikValues => {
    if (formVehiclesProperties.isValid) {
      return formVehiclesProperties.values;
    } else {
      return {};
    }
  };
  const getLabelProductType = (): string => {
    return {
      [TypeProperties.AUTO]: 'Auto',
      [TypeProperties.VEHICULOS]: 'Auto',
      [TypeProperties.MOTOCICLETA]: 'Motoclicleta',
      [TypeProperties.MOTORA]: 'Motora',
    }[props.productType];
  };

  return (
    <div className="grid__span-6" ref={formVehiclesRef}>
      {quantity.map((_, quantityIndex) => (
        <div key={quantityIndex}>
          <TitleWhitImage
            key={`title__whit__image__mascota-${quantityIndex}`}
            title={`${getLabelProductType()} ${quantityIndex + 1}`}
            image={getLabelProductType() === 'Auto' ? Images.auto : Images.moto}
            styleInLineImage={{
              width: '2rem',
              height: '2rem',
            }}
          ></TitleWhitImage>
          <div
            className="form__pets__container grid rg-0 grid__col-6 grid__span-6"
            key={`grid-${quantityIndex}`}
          >
            {props.properties.map((property, indexItem) => (
              <Input
                key={`${property.id}-${quantityIndex}-${indexItem}`}
                WrapperClassName={
                  property.attributes.large ? 'grid__span-6' : 'grid__span-3'
                }
                label={property.attributes.name}
                id={`create-properties-${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`}
                type={property.attributes.type as TypeInput}
                name={`${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`}
                onChange={formVehiclesProperties.handleChange}
                onBlur={formVehiclesProperties.handleBlur}
                value={
                  formVehiclesProperties.values[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] || ''
                }
                isTouched={
                  formVehiclesProperties.touched[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] as boolean
                }
                errorInput={
                  formVehiclesProperties.errors[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] as string
                }
                errorLabel={
                  formVehiclesProperties.errors[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] as string
                }
                isRequired={property.attributes.required as boolean}
                isDisabled={false}
                maxLength={property.attributes.length as number}
                form={formVehiclesProperties}
                textTransform={
                  property.attributes.name === 'Placa' ? true : false
                }
              />
            ))}
            <div
              key={`properties__divider__${props.typeProduct}-${quantityIndex}`}
              className="create__properties__atributes__divider grid__span-6"
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
});
